const About = () => {
    return(
        <div className="py-10">
            <h2 className="text-5xl">Hello World!</h2>
            <h2 className="text-5xl">This is home page World</h2>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque praesentium cumque earum? Ducimus neque placeat quam iure veritatis, aliquam exercitationem.</p>
        </div>
    )
}

export default About;