import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './Home';
import About from './About';
import Service from './Services';
import Contact from './Contact';
import Fahim from './components/Footer';

function App() {
  return (
  <>
    <BrowserRouter>
    <Navbar/>
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Service />} />
          <Route path="/Contact" element={<Contact />} />
       </Routes>
       <Fahim/>
    </BrowserRouter>
  </>
  );
}

export default App;
